import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';

const AUTH_API = `${environment.baseURL}/rest-auth/`;
const BASE_API = `${environment.baseURL}/`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private storageService: StorageService) {}

  OTPLogin(email: any, password: any, OTPCode: any): Observable<any> {
    return this.http.post(
      AUTH_API + 'admin/login/',
      {
        email:email,
        password:password,
        otp_code:OTPCode
      },
      httpOptions
    );
  }

  logout(): Observable<any> {
    return this.http.post(AUTH_API + 'logout/', { }, httpOptions);
  }

  saveToken(token:string):void {
    this.storageService.set("token",token)
  }

  getToken():any {
    return this.storageService.get("token");
  }

  removeToken():void {
    this.storageService.remove("token")
  }

  isLoggedIn():boolean {
    if (this.getToken() !== null) {
      return true;
    }
    else {
      return false
    }

  }
}