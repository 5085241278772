<div class="dashboard-wrapper"> 

    <aside class="dashboard-sidenav">
        <div class="sidenav-logo">
            <a routerLink="home"> <img src="../../../../../assets/images/logo/b2b_logo.png"> </a>
        </div>
        <div class="sidenav">
            <div class="menu-item" routerLink="/admin/home" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>home</mat-icon>
                </div>
                <div class="menu-text"> 
                    Home
                </div>
            </div>
            <div class="menu-item" routerLink="/admin/survey" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>insights</mat-icon>
                </div>
                <div class="menu-text"> 
                    Surveys
                </div>
            </div>
            <div class="menu-item" routerLink="/admin/bella" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>chat</mat-icon>
                </div>
                <div class="menu-text"> 
                    Bella
                </div>
            </div>
        </div>
        <div class="sidenav-footer">
            logged in as 
        </div>
    </aside>

    <!-- Only displays on mobile -->
    <div class="dashboard-mobile-toolbar">
        
        <div>
            <button mat-icon-button (click)="toggleMobileSideNav()">
                <mat-icon >menu</mat-icon>
            </button>
        </div>
        <div class="mobile-toolbar-logo">
            <div>
                <a routerLink="home"> <img src="../../../../../assets/images/logo/b2b_logo.png"> </a>
            </div>
        </div>

    </div>

    <!-- Only displays on mobile -->
    <div [@openClose]="isOpen ? 'open' : 'closed'" [class.hide-div]='!isOpen'> 
        <div class="mobile-sidenav">
            <button mat-icon-button class="close-icon" (click)="toggleMobileSideNav()">
                <mat-icon >close</mat-icon>
            </button>
            <div class="menu-item" routerLink="/" (click)="toggleMobileSideNav()" routerLinkActive="active">
                <div class="menu-icon"> 
                    <mat-icon>home</mat-icon>
                </div>
                <div class="menu-text"> 
                    Home
                </div>
            </div>
        </div>
        <div class="mobile-sidenav-footer">
            <mat-divider></mat-divider>
            <button mat-menu-item class="mobile-sidenav-logout" (click)="logout()">
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
            </button>
            <mat-divider></mat-divider>

            <div class="mobile-footer-privacy">
                logged in as ...
            </div>
            
        </div>
      
    </div>

    <main class="dashboard-main">
        <div class="dashboard-header">
            
            <div class="header-menu"> 
                <button mat-icon-button [matMenuTriggerFor]="headerMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #headerMenu="matMenu">
                    <button mat-menu-item (click)="logout()">
                        <mat-icon>power_settings_new</mat-icon>
                        <span>Logout</span>
                    </button>
                </mat-menu>
            </div>
        </div>

        <div class="dashboard-content">
            <router-outlet></router-outlet>
        </div>
    </main>

</div>
