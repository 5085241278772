import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router){};
  
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot):boolean{
    
    let isLoggedIn = this.authService.isLoggedIn()
    if (isLoggedIn) {
      return true
    }
    else {
      this.router.navigate(['auth/login']);
      return false
    }
  }
  
}
