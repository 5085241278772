import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'app-dashboard',
  animations: [
    trigger('openClose', [
      state('open', style({
        position: 'absolute',
        width: '75%',
        height: '100%',
        backgroundColor: '#e486b3',
        zIndex: '10'
      })),
      state('closed', style({
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '10%',
        zIndex:'-10',
        opacity: 0
      })),
      transition('open => closed', [
        animate('500ms ease-in')
      ]),
      transition('closed => open', [
        animate('300ms ease-in')
      ]),
    ]),
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // profile!:UserProfile;
  hideMobileNav:boolean = false;
  isOpen = false;

  constructor(
    private storageService:StorageService,
    private router: Router, 
    private authService:AuthService,
    ) { }

  ngOnInit(): void {
    // this.requestUserProfile();
  }
  
  logout(): void {
    this.authService.logout().subscribe({
      next: data => {
        this.router.navigate(["/auth/login"]);
        this.authService.removeToken();
      },
      error: err => {
        this.authService.removeToken();
      }
    });
  }

  toggleMobileSideNav() {
    this.hideMobileNav  = !this.hideMobileNav;
    this.isOpen  = !this.isOpen;
  }
}
