import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// ********** DO's **********
// DO import modules that should be instantiated once in your app.
// DO place services in the module, but do not provide them. They are already injected in root

// ********** DONT's **********
// DO NOT declare components, pipes, directives.
// DO NOT import the Core Module into any modules other than the App Module. This should be instaniated once.

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class CoreModule { }
